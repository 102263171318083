import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Link from 'next/link'
import NextImage from 'next/image'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import dynamic from 'next/dynamic'
import apiservice from '../../common/services/apiService'
import { useCheckYOffset } from '../../utils/common'

const DiscussProject = dynamic(() => import('../../components/modals/DiscussProject'))
const FooterDetails = dynamic(() => import('../../components/footer/FooterDetails'))
const FooterColumns = dynamic(() => import('../../components/footer/FooterColumns'))
const FooterRatingsAndSocialMedia = dynamic(() => import('../../components/footer/FooterRatingsAndSocialMedia'))
const FooterOurRecognitions = dynamic(() => import('../../components/footer/FooterOurRecognitions'))
const FooterBottomDetails = dynamic(() => import('../../components/footer/FooterBottomDetails'))

React.useLayoutEffect = React.useEffect


function Troofooter() {
	const router = useRouter()
	const dispatch = useDispatch()
	const [show, setShow] = useState(false)
	const showModal = () => setShow(true)
	const hideModal = () => setShow(false)

	const discussAProjectClass = useCheckYOffset(80, 'hideBtn', '<')
	const [footerData, setFooterData] = useState(null)

	const [footerColumns, setFooterColumns] = useState([])

	const setColumns = () => {
		const columns = footerData?.footer?.footer?.footerColumns?.map(
			(column) => ({
				column: column?.title,
				colLength: column?.links?.length,
				seeMore: false,
			}),
		)
		setFooterColumns(columns)
	}

	const handleShowMore = (index) => {
		let columnObj = {
			...footerColumns[index],
			seeMore: !footerColumns[index].seeMore,
		}
		let columnArray = [...footerColumns]

		columnArray[index] = columnObj

		setFooterColumns(columnArray)
	}

	const fetchFooter = async () => {
		const list = await apiservice.getapi('get-footer')

		dispatch({
			type: "SET_SUBSCRIBE_MODAL_DETAILS",
			payload: list?.data?.footer?.footer?.subscribe_modal_details
		})
		dispatch({
			type: "SET_COMMON_DATA",
			payload: {
				top_case_studies: list?.data?.footer?.footer?.top_case_studies,
				trusted_by_slider: list?.data?.footer?.footer?.trusted_by_slider
			}
		})
		setFooterData({ ...list.data })
	}

	useEffect(() => {
		setColumns()
		// eslint-disable-next-line
	}, [footerData])

	useEffect(() => {
		fetchFooter()
		// eslint-disable-next-line
	}, []) //This will run only once

	return (
		<>
			<div
				className={`sticky-bottom footerBtn ${discussAProjectClass} ${router?.asPath?.includes('request-proposal') ? 'd-none' : ''}`}
				// className={`
				// 	sticky-bottom footerBtn ${discussAProjectClass} 
				// 	${router?.asPath?.includes('request-proposal') ? 'd-none' : ''} `}
			>
				<Link 
					href="/request-proposal" 
					className="btn btn-primary"
					onClick={(e) => {
						e.preventDefault();
						showModal();
					}}
				>
					Discuss a project
				</Link>

				<DiscussProject show={show} hide={hideModal} />
			</div>
			{footerData && (
				<footer className="footer-section bg-footer">
					{footerData?.footer?.footer && (
						<>
							<div className="footer-details footer-bottom">
								<Container>
									<Row>
										<Col md={12} xl={12} lg={12}>
											<div className="footer-logo">
												<Link href="/">
													<NextImage
														src="/images/footer-logo.svg"
														// src="/images/footer-xmas-logo.svg"
														alt="logo"
														width={166}
														height={50}
													/>
												</Link>
											</div>
										</Col>
									</Row>
									<Row>
										<Col md={12} xl={12} lg={12}>
											<div className="footer-details-inner">
												<Row>
													<FooterDetails
														offices={footerData?.footer?.footer?.offices}
													 	label={footerData?.footer?.footer?.label}
													 	CIN={footerData?.footer?.footer?.CIN}
													 	GSTIN={footerData?.footer?.footer?.GSTIN}
													/>
													<FooterColumns
														columns={footerData?.footer?.footer?.footerColumns}
														footerColumns={footerColumns}
														handleShowMore={handleShowMore}
													/>
												</Row>
												<Row>
													<FooterOurRecognitions
														recognitions={footerData?.footer?.footer?.our_recognitions}
													/>
												</Row>
											</div>
										</Col>
									</Row>
								</Container>
							</div>
							<div className="footer-second-row footer-bottom">
								<FooterRatingsAndSocialMedia
									Ratings={footerData?.footer?.footer?.Ratings}
									socialNetworks={footerData?.footer?.footer?.socialNetworks}
								/>
							</div>
							<div className="footer-copy-right footer-bottom">
								<FooterBottomDetails
									copyWriteText={footerData?.footer?.footer?.copy_write_text}
									termsLink={footerData?.footer?.footer?.terms_link}
									pricacyPolicyLink={footerData?.footer?.footer?.pricacy_policy_link}
								/>
							</div>
						</>
					)}
				</footer>
			)}
		</>
	)
}

export default Troofooter
